/* eslint-disable camelcase */
import React, { Fragment, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Cookies from 'js-cookie'
import { space, fontSizes, colors, COLOR_CONSTANTS, radius } from 'theme'
import bgImageNew from 'static/images/home/bg_home_background.svg'
import { Flex, Box } from './Layout'
import { H3, Text } from './Typography'
import Button from './Button'
import logo from '../static/logo/logo_big.png'
import flag_ukraine from '../static/svg/flag_ukraine.svg'
import hamburger from '../static/svg/hamburger.svg'
import DropdownMenu from './DropdownMenu'
import ImageWithFallback from './ImageWithFallback'
import LocalImage from './LocalImage'
import clear from '../static/svg/clear.svg'
import bannerBackgrounds from '../static/images/banner_background.png'
import Container from './Container'

const COOKIE_EXPIRATION_DAYS = 30

const BgImg = styled.img`
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
  position: absolute;
`

const BannerWrapper = styled(Flex)`
  background: url(${({ backgroundUrl }) => backgroundUrl || bannerBackgrounds});
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 10;
  width: 100%;
  text-decoration: none;
  ${({ hasButton }) => !hasButton && 'cursor: pointer;'}
`

const BannerTitle = styled(Text)`
  text-transform: uppercase;
`

const IconWrapper = styled(Box)`
  position: absolute;
  top: 5px;
  background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledFlex = styled(Flex)`
  position: fixed;
  top: ${({ top }) => top || 0};
  background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  width: 100%;
  z-index: 10;
`

const HomeLink = styled.a`
  text-decoration: none;
  color: ${colors.primaryText};
  margin-left: ${space.m};
  &:visited {
    color: initial;
  }
`

const MenuLinkWrapper = styled(Flex)`
  position: relative;
  height: 100%;
  align-items: center;
  div {
    display: none;
  }
  &:hover {
    div {
      display: flex;
    }
  }
`

const StyledLink = styled(Text)`
  padding-top: ${space.s};
  padding-bottom: ${space.s};
  color: ${({ color }) => color || COLOR_CONSTANTS.COSMIC_ENERGY};
  text-decoration: none;
  font-size: ${fontSizes.s};
  &:hover {
    color: ${colors.primary};
  }
`

const MenuChildrenWrapper = styled(Flex)`
  z-index: 1;
  position: absolute;
  top: 100%;
  flex-direction: column;
  width: max-content;
  min-width: 210px;
  padding: ${space.xs} 0;
  box-shadow: 0px 4px 20px rgba(39, 40, 49, 0.1);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  left: 50%;
  transform: translate(-50%);
  &::after,
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border: solid transparent;
  }
  &::after {
    border-bottom-color: ${colors.white};
    border-width: 6px;
    left: calc(50% - 1px);
    transform: translate(calc(-50% + 1px));
  }
  &::before {
    border-bottom-color: ${COLOR_CONSTANTS.SOLITUDE};
    border-width: 7px;
    left: 50%;
    transform: translate(-50%);
  }
`

const ButtonLink = styled(Button.Secondary)`
  display: flex;
  text-decoration: none;
  border: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  &:hover {
    background-color: #ffffff;
    border: 1px solid rgba(253, 83, 89, 0.1);
  }
`
const ButtonLinkDropdownMenu = styled(ButtonLink)`
  justify-content: flex-start;
`

const BannerButton = styled(Button.Primary)`
  padding-left: ${space.l};
  padding-right: ${space.l};
  margin: 0 ${space.m};
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`

const MENU_LINKS = [
  {
    label: 'Agencies',
    to: '/agencies/',
  },
  {
    label: 'Businesses',
    to: '/businesses/',
  },
  {
    label: 'Pricing',
    to: '/pricing',
  },
  {
    label: 'Insights',
    to: '/insights/',
  },
  {
    label: 'About',
    to: '/about/',
  },
]

const Header = ({ getBannerHeight }) => {
  const data = useStaticQuery(
    graphql`
      query {
        campaigns {
          campaigns {
            id
            picture_url
            background_url
            title
            button_text
            button_color_text
            button_color_background
            text_color
            source
            medium
            pages
            link
          }
        }
        allWordpressCategory(filter: { slug: { ne: "uncategorized" } }) {
          edges {
            node {
              id
              name
              count
              slug
            }
          }
        }
      }
    `
  )

  const {
    campaigns: { campaigns },
  } = data

  let { allWordpressCategory: { edges: postCategories = [] } = {} } = data

  postCategories = postCategories.map(({ node: { slug, name } }) => {
    return { to: `/insights/${slug}`, label: name }
  })

  if (postCategories.length) {
    const insightsIndex = MENU_LINKS.findIndex(({ to }) => to === '/insights/')
    if (insightsIndex > -1) {
      MENU_LINKS[insightsIndex].children = postCategories
    }
  }

  const isSSR = typeof window === 'undefined'
  const [showBanner, setShowBanner] = useState(false)
  const [banner, setBanner] = useState({})
  const [bannerHeight, setBannerHeight] = useState(0)
  const bannerRef = useRef(null)

  useEffect(() => {
    if (bannerRef.current) {
      const { clientHeight } = bannerRef.current
      setBannerHeight(clientHeight)
      getBannerHeight(clientHeight)
      setTimeout(() => {
        setShowBanner(false)
        getBannerHeight(0)
      }, 10000)
    }
  })

  if (!banner.id && !isSSR) {
    let path = window.location.pathname.split('/')[1]
    const params = new URL(window.location.href).searchParams
    let utm_source = params.get('utm_source')
    let utm_medium = params.get('utm_medium')

    if (!path) {
      path = 'home'
    }

    const setBannerCookie = async ({ campaign }) => {
      await Cookies.set(`banner`, JSON.stringify(campaign), { expires: COOKIE_EXPIRATION_DAYS })
    }

    const findBanner = async () => {
      if (!utm_source && !utm_medium) {
        utm_source = ''
        utm_medium = ''
        let campaign = await Cookies.get(`banner`)
        if (campaign) {
          campaign = JSON.parse(campaign)
          const bannerClosed = await Cookies.get(`banner_${campaign.id}_${campaign.source}_${campaign.medium}`)
          if (!bannerClosed) {
            ;({ source: utm_source, medium: utm_medium } = campaign)
          }
        }
      }

      for (const campaign of campaigns) {
        if (
          (campaign.pages.includes(path) || campaign.pages === 'all_pages') &&
          utm_source === campaign.source &&
          utm_medium === campaign.medium
        ) {
          const bannerClosed = await Cookies.get(`banner_${campaign.id}_${utm_source}_${utm_medium}`)
          if (!bannerClosed) {
            setBanner(campaign)
            setShowBanner(true)
            setBannerCookie({ campaign })
          }
        }
      }
    }

    findBanner()
  }

  const handleDismiss = async () => {
    await Cookies.set(`banner_${banner.id}_${banner.source}_${banner.medium}`, true, {
      expires: COOKIE_EXPIRATION_DAYS,
    })
    setShowBanner(false)
    getBannerHeight(0)
  }

  const handleBannerClick = async ({ link }) => {
    if (link) {
      await Cookies.set('gotoAfterLogin', link, {
        expires: COOKIE_EXPIRATION_DAYS,
      })
      window.location.href = '/signup'
    }
  }

  const {
    title,
    picture_url,
    background_url,
    link,
    button_text,
    button_color_text,
    button_color_background,
    text_color,
  } = banner

  return (
    <Fragment>
      {showBanner && (
        <BannerWrapper hasButton={!!button_text} ref={bannerRef} backgroundUrl={background_url}>
          <Box position="relative" width="100%">
            <Flex
              minHeight="100px"
              py="m"
              width="100%"
              onClick={() => {
                if (!button_text) {
                  handleBannerClick({ link })
                }
              }}
            >
              <Flex
                pl="m"
                pr={{ mobile: 'l', mobileLarge: 'm' }}
                alignItems="center"
                width="100%"
                justifyContent="center"
                flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
              >
                <BannerTitle
                  color={text_color || colors.primaryText}
                  fontSize="xxl"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {title}
                </BannerTitle>
                {button_text && (
                  <BannerButton
                    mx="m"
                    onClick={() => {
                      handleBannerClick({ link })
                    }}
                    backgroundColor={button_color_background}
                    textColor={button_color_text}
                  >
                    {button_text.toUpperCase()}
                  </BannerButton>
                )}
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" position="relative">
                {picture_url && (
                  <Box maxWidth="200px" height="100%" display={{ mobile: 'none', tablet: 'block' }} pr="m">
                    <ImageWithFallback
                      source={picture_url}
                      fallbackSource=""
                      height="100%"
                      width="120px"
                      maxHeight="150px"
                    />
                  </Box>
                )}
              </Flex>
            </Flex>
            <IconWrapper onClick={handleDismiss} right={{ mobile: '9px', tablet: '5px' }}>
              <LocalImage width="9px" height="9px" src={clear} />
            </IconWrapper>
          </Box>
        </BannerWrapper>
      )}
      <Container position="relative" maxWidth="1600px" width="100%" height="100%">
        <BgImg src={bgImageNew} />
      </Container>
      <StyledFlex
        top={showBanner ? `${bannerHeight}px` : 0}
        justifyContent="space-between"
        alignItems="center"
        pr="m"
        py="m"
      >
        <BgImg src={bgImageNew} />
        <HomeLink href="/">
          <Flex alignItems="center">
            <img src={logo} width="180px" alt="Agency Vista" />
            <LocalImage src={flag_ukraine} alt="Vista Social" width="20px" ml="10px" mt="8px" />
          </Flex>
        </HomeLink>
        <Flex display={{ mobile: 'none', tablet: 'flex' }} justifyContent="center" alignItems="center" height="50px">
          {MENU_LINKS.map(({ label, to, children }) => (
            <MenuLinkWrapper key={to}>
              <StyledLink as="a" href={to} px={{ tablet: 's', desktop: 'm' }}>
                {label}
              </StyledLink>
              {children && (
                <MenuChildrenWrapper>
                  {children.map(({ label, to }) => (
                    <StyledLink
                      as="a"
                      key={to}
                      href={to}
                      color={COLOR_CONSTANTS.SALUTE}
                      px={{ tablet: 's', desktop: 'm' }}
                    >
                      {label}
                    </StyledLink>
                  ))}
                </MenuChildrenWrapper>
              )}
            </MenuLinkWrapper>
          ))}
        </Flex>
        <Flex display={{ mobile: 'flex', tablet: 'none' }}>
          <DropdownMenu
            WrapperComp={
              <button type="button">
                <img src={hamburger} width="28" alt="Menu" />
              </button>
            }
            right={0}
            isTriangleVisible={false}
          >
            <StyledLink as="a" href="/login" px="m">
              Log in
            </StyledLink>
            <ButtonLinkDropdownMenu as="a" href="/signup">
              <Text py="s">Create free account</Text>
            </ButtonLinkDropdownMenu>
            {MENU_LINKS.map(({ label, to }) => (
              <Flex flexDirection="column" key={to}>
                <StyledLink as="a" href={to} px="m">
                  {label}
                </StyledLink>
              </Flex>
            ))}
          </DropdownMenu>
        </Flex>
        <Flex display={{ mobile: 'none', mobileLarge: 'none', tablet: 'none' }}>
          <DropdownMenu
            WrapperComp={
              <button type="button">
                <img src={hamburger} width="28" alt="Menu" />
              </button>
            }
            right={0}
            isTriangleVisible={false}
          >
            <StyledLink as="a" href="/login" px="m">
              Log in
            </StyledLink>
            <ButtonLinkDropdownMenu as="a" href="/signup">
              <Text>Create free account</Text>
            </ButtonLinkDropdownMenu>
          </DropdownMenu>
        </Flex>
        <Flex display={{ mobile: 'none', tablet: 'flex' }} justifyContent="flex-end" alignItems="center">
          <StyledLink as="a" href="/login" px={{ tablet: 's', desktop: 'm' }}>
            Log in
          </StyledLink>
          <ButtonLink as="a" href="/signup">
            <Text>Create free account</Text>
          </ButtonLink>
        </Flex>
      </StyledFlex>
    </Fragment>
  )
}

export default Header
