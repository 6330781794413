import { rem } from 'polished'
import Img from 'gatsby-image'
import he from 'he'
import placeholderImg from './static/images/blog_placeholder.png'

export const pxToRem = (pxVal) => rem(pxVal, 18)
export const stripHtml = (string) => string.replace(/<[^>]*>?/gm, '')
export const parseTextFromServer = (string) => stripHtml(he.decode(string))
export const getImageByFeaturedMedia = (featuredMedia) => {
  if (featuredMedia) {
    const { alt_text: alt, localFile: { childImageSharp: { fluid } = {} } = {} } = featuredMedia
    return { WrapperComp: Img, featuredImageProps: { alt, fluid } }
  }
  return { WrapperComp: 'img', featuredImageProps: { alt: 'Agency Vista', src: placeholderImg } }
}

export const formatToNumberWithCommas = (number) => {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const mapValueToReactSelect = ({ id, name, description }) => ({ value: id, label: name || description })
export const mapArrayToReactSelect = (arr) => arr.map(mapValueToReactSelect)

export const initializeArrayWithRange = (end, start = 0) =>
  Array.from({ length: end + 1 - start }).map((v, i) => i + start)
