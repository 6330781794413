import styled from 'styled-components'
import { transparentize, desaturate } from 'polished'
import { space, radius, colors, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  align-items: center;
  justify-content: center;
  padding-left: ${space.m};
  padding-right: ${space.m};
  user-select: none;
  height: ${({ isSmall }) => (isSmall ? pxToRem(30) : pxToRem(40))};
  transition: background-color 0.12s ease-in-out, border-color 0.12s ease-in-out, color 0.15s ease-in-out;
`

ButtonBase.defaultProps = {
  fontSize: 'm',
}

const Primary = styled(ButtonBase)`
  background-color: ${({ isDisabled }) => (isDisabled ? desaturate(0.3, colors.primary) : colors.primary)};
  color: ${colors.white};
  border-radius: ${radius.m};
  ${({ isDisabled }) => isDisabled && `cursor: initial;`}
  ${({ isInverted }) =>
    isInverted &&
    `
    background-color: ${colors.white};
    color: ${colors.primary};
  `}
  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `
        background-color: #f25359;
  `}
  }
`

const Reversed = styled(ButtonBase)`
  background-color: ${({ isDisabled }) => (isDisabled ? desaturate(0.3, colors.white) : colors.white)};
  color: ${colors.primary};
  border-radius: ${radius.m};
  ${({ isDisabled }) => isDisabled && `cursor: initial;`}
  ${({ isInverted }) =>
    isInverted &&
    `
    background-color: ${colors.white};
    color: ${colors.primary};
  `}
`

const Secondary = styled(ButtonBase)`
  background-color: ${transparentize(0.9, colors.primary)};
  color: ${colors.primary};
  border-radius: ${radius.s};
`

const Gray = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  color: ${COLOR_CONSTANTS.SALUTE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  &:hover {
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

Secondary.defaultProps = {
  isSmall: true,
}

export default {
  Primary,
  Secondary,
  Reversed,
  Gray,
}
