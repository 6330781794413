import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LocalImage from 'components/LocalImage'

import COMPANY_PLACEHOLDER from '../static/svg/company.svg'

const isImageValid = ({ source }) => {
  return new Promise((resolve) => {
    try {
      const img = document.createElement('img')
      img.onerror = () => resolve(false)
      img.onload = () => resolve(true)
      img.src = source
    } catch (e) {
      // ReferenceError: document is not defined
      resolve(true)
    }
  })
}

const ImageWithFallback = ({ source, fallbackSource, alt, fallbackError, ...props }) => {
  const [src, setSrc] = useState(source)
  const [called, setCalled] = useState(false)
  if (!called && typeof window !== 'undefined') {
    setCalled(true)
    isImageValid({ source }).then((isValid) => {
      if (!isValid) {
        if (fallbackSource) {
          setSrc(fallbackSource)
        } else {
          fallbackError()
        }
      }
    })
  }

  return (
    <LocalImage
      src={src}
      alt={alt}
      onError={() => {
        setSrc(fallbackSource)
      }}
      {...props}
    />
  )
}

ImageWithFallback.defaultProps = {
  fallbackSource: COMPANY_PLACEHOLDER,
  source: COMPANY_PLACEHOLDER,
  alt: '',
  fallbackError: () => {},
}

ImageWithFallback.propTypes = {
  source: PropTypes.string,
  fallbackSource: PropTypes.string,
  alt: PropTypes.string,
  fallbackError: PropTypes.func,
}

export default ImageWithFallback
