import { createGlobalStyle } from 'styled-components'
import { fluidRange } from 'polished'
import { pxToRem } from 'helpers'

export const fontStack = "'Neutrif Pro', sans-serif"

export const COLOR_CONSTANTS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  SALUTE: '#272831',
  COSMIC_ENERGY: '#9193A9',
  FLUORESCENT_RED: '#FD5359',
  ZHEN_ZHU_BAI_PEARL: '#FAFAFD',
  FLAT_BLUE: '#3077B0',
  SOLITUDE: '#E9EAF1',
  GLACIER_PEARL: '#D6D7E1',
  BOYZONE: '#2D9CDB',
  RED: '#FF0000',
  ROCKMAN_BLUE: '#4AA1EB',
  AMERICANA: '#0077B5',
  JUNGLE_GREEN: '#27AE60',
  ALGAE: '#55AA68',
  VIOLET_WHIMSEY: '#D6D7E0',
  CARPACCIO: '#E34234',
  BIO_BLUE: '#3B5998',
  BRIGHT_NAVY_BLUE: '#417EE0',
  VENUS_MIST: '#60616C',
  ICEWIND_DALE: '#E9ECEF',
  RED_STAR: '#FA5252',
}

export const colors = {
  primary: COLOR_CONSTANTS.FLUORESCENT_RED,
  primaryText: COLOR_CONSTANTS.SALUTE,
  secondaryText: COLOR_CONSTANTS.COSMIC_ENERGY,
  ternaryText: COLOR_CONSTANTS.VENUS_MIST,
  white: COLOR_CONSTANTS.WHITE,
  black: COLOR_CONSTANTS.BLACK,
}

export const radius = {
  s: '2px',
  m: '4px',
  l: '6px',
  xl: '8px',
  circle: '50%',
  pill: '9999px',
}

export const space = {
  xxs: pxToRem(2),
  xs: pxToRem(4),
  s: pxToRem(8),
  m: pxToRem(16),
  l: pxToRem(32),
  xl: pxToRem(64),
  xxl: pxToRem(128),
  xxxl: pxToRem(256),
}

export const fontSizes = {
  xxs: pxToRem(10),
  xs: pxToRem(12),
  s: pxToRem(14),
  m: pxToRem(16),
  l: pxToRem(18),
  xl: pxToRem(20),
  xxl: pxToRem(24),
  xxxl: pxToRem(32),
  xxxxl: pxToRem(48),
}

export const fontWeights = {
  light: 200,
  normal: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
}

export const lineHeights = {
  s: 1.2,
  m: 1.3,
  l: 1.4,
  xl: 1.5,
}

export const GlobalStyles = createGlobalStyle`
  html {
    ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '18px',
    })}
    overflow-x: hidden;
  }
  body {
    font-family: ${fontStack};
    font-weight: 400;
    color: ${colors.primaryText};
    background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
    min-width: 320px;
  }
  body,
  button,
  input,
  select {
    font-family: ${fontStack};
  }
  button {
    font-family: inherit;
    font-size: ${fontSizes.m};
    background-color: transparent;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
  }
  input[type='radio'] {
    visibility: hidden;
    position: absolute;
  }
  input[type='radio'] + label {
    display: flex;
    position: relative;
  }
  input[type='radio'] + label:before {
    display: flex;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 2px;
    vertical-align: baseline;
    content: ' ';
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  }
  input[type='radio']:checked + label:before {
    background: ${colors.white};
    border: 5px solid ${colors.primary};
  }
  input[type='radio'] + label:before {
    border-radius: ${radius.pill};
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${COLOR_CONSTANTS.FLUORESCENT_RED};
  }
  .slick-initialized .slick-slide {
    display: inline-block !important;
  }
  .slick-slide{
    float: none !important;
  }
  .css-17rus17-ValueContainer{
    padding-left: 0px !important;
  }
  .grecaptcha-badge { 
    visibility: hidden; 
  }
  .primary-text {
    color: ${colors.primary};
  }
  .giphy-embed {
    margin: 0 auto;
    max-width: 100%;
    width: 100%; 
  }
  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }
  .twitter-tweet iframe {
    width: 100% !important;
  }
`

export const breakpoints = ['900px']
breakpoints.mobile = '0'
breakpoints.mobileLarge = '500px'
breakpoints.tablet = '768px'
breakpoints.desktop = '1024px'

// This is needed for styled-system to work
export const theme = {
  colors,
  space,
  fontSizes,
  fontWeights,
  breakpoints,
  lineHeights,
}
