import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { theme, GlobalStyles, space } from 'theme'
import SEO from 'components/SEO'
import CookieNotice from 'components/CookieNotice'
// import Header from 'components/Header'
import HeaderLean from 'components/HeaderLean'
import Header from 'components/Header'
import favicon16 from 'static/images/favicon16.png'
import favicon32 from 'static/images/favicon32.png'
import favicon64 from 'static/images/favicon64.png'

import 'sanitize.css'
import 'fonts.css'
import '@reach/dialog/styles.css'
import { pxToRem } from 'helpers'

const COOKIE_NOTICE_OFFSET = 25

const Wrapper = styled.div`
  padding-bottom: ${COOKIE_NOTICE_OFFSET}px;
`

const BodyWrapper = styled.div`
  padding-top: ${({ isHeaderVisible, bannerHeight }) =>
    isHeaderVisible ? `calc(${pxToRem(50)} + 2 * ${space.m} + ${pxToRem(bannerHeight)})` : `${pxToRem(bannerHeight)}`};
`

const App = ({ isHeaderVisible, fullHeader = false, children }) => {
  const [bannerHeight, setBannerHeight] = useState(0)

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <SEO />
        <Helmet>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="shortcut icon" type="image/png" href={favicon64} />
          <script async src="https://www.instagram.com/embed.js" />
        </Helmet>
        <GlobalStyles />
        {isHeaderVisible && <Header getBannerHeight={(param) => setBannerHeight(param)} />}
        <BodyWrapper isHeaderVisible={isHeaderVisible} bannerHeight={bannerHeight}>
          {children}
        </BodyWrapper>
        <CookieNotice />
      </Wrapper>
    </ThemeProvider>
  )
}

App.defaultProps = {
  isHeaderVisible: true,
}

App.propTypes = {
  isHeaderVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  fullHeader: PropTypes.bool,
}

export default App
