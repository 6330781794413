import styled from 'styled-components'
import { Text } from 'components/Typography'

const Link = styled(Text)`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${({ color }) => color || 'initial'};
`

Link.defaultProps = {
  as: 'a',
}

export default Link
