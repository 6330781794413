import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { Box, Flex } from '../Layout'
import { colors, COLOR_CONSTANTS, space } from 'theme'

const DropdownMenuWrapper = styled(Flex)`
  z-index: 1;
  position: absolute;
  top: calc(100% + 6px);
  flex-direction: column;
  ${({ right }) =>
    right &&
    `
      right: ${right}
  `};
  ${({ left }) =>
    left &&
    `
      left: ${left}
  `};
  width: max-content;
  min-width: 150px;
  padding: ${space.xs} 0;
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};

  ${({ isTriangleVisible }) =>
    isTriangleVisible &&
    `
      &::after,
      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: 100%;
        height: 0;
        width: 0;
        border: solid transparent;
      }
      &::after {
        border-bottom-color: ${colors.white};
        border-width: 6px;
        right: 11px;
      }
      &::before {
        border-bottom-color: ${COLOR_CONSTANTS.SOLITUDE};
        border-width: 7px;
        right: 10px;
      }
  `}
`

class DropdownMenu extends React.Component {
  state = { isOpen: false }
  handleToggleMenu = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }
  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }
  handleClickInside = () => {
    const { isDismissedOnClickInside } = this.props
    if (isDismissedOnClickInside) {
      const { isOpen } = this.state
      if (isOpen) {
        this.setState({ isOpen: false })
      }
    }
  }
  render() {
    const { isOpen } = this.state
    const { WrapperComp, children, left, right, isTriangleVisible } = this.props
    return (
      <Box position="relative" onClick={this.handleClickInside}>
        {React.cloneElement(WrapperComp, { onClick: this.handleToggleMenu })}
        {isOpen && (
          <DropdownMenuWrapper left={left} right={right} isTriangleVisible={isTriangleVisible}>
            {children}
          </DropdownMenuWrapper>
        )}
      </Box>
    )
  }
}

DropdownMenu.defaultProps = {
  isDismissedOnClickInside: false,
  isTriangleVisible: true,
  right: 0,
}

DropdownMenu.propTypes = {
  WrapperComp: PropTypes.node,
  children: PropTypes.node,
  isDismissedOnClickInside: PropTypes.bool,
  isTriangleVisible: PropTypes.bool,
  left: PropTypes.number,
  right: PropTypes.number,
}

export default onClickOutside(DropdownMenu)
