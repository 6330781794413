import React from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import { space, COLOR_CONSTANTS } from 'theme'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Link from 'components/Link'
import clear from 'static/svg/clear.svg'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space.xxs} ${space.m};
  background-color: ${COLOR_CONSTANTS.ICEWIND_DALE};
  z-index: 10;
`

const StyledButton = styled.button`
  margin-left: ${space.xs};
  padding: ${space.s};
  outline: 0;
`

const StyledText = styled(Text)`
  text-decoration: underline;
`

const StyledLink = styled(Link)`
  display: inline-block;
`

const IS_COOKIES_ACCEPTED = 'isCookiesAccepted'
const COOKIE_EXPIRATION_DAYS = 365

class CookieNotice extends React.Component {
  state = { isVisible: false }
  componentDidMount = async () => {
    const isCookiesAccepted = await Cookies.get(IS_COOKIES_ACCEPTED)
    this.setState({ isVisible: !isCookiesAccepted })
  }
  handleAcceptCookiesConsent = () => {
    Cookies.set(IS_COOKIES_ACCEPTED, true, { expires: COOKIE_EXPIRATION_DAYS })
    this.setState({ isVisible: false })
  }
  render() {
    const { isVisible } = this.state
    if (!isVisible) {
      return null
    }
    return (
      <Wrapper>
        <Text fontSize="xs">
          By continuing to use this site you consent to the use of cookies in accordance with&nbsp;
          <StyledLink href="/privacy">
            <StyledText as="span" fontSize="xs">our cookie policy</StyledText>
          </StyledLink>
          .
        </Text>
        <StyledButton onClick={this.handleAcceptCookiesConsent} type="button">
          <LocalImage src={clear} />
        </StyledButton>
      </Wrapper>
    )
  }
}

export default CookieNotice
