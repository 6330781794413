import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from 'components/Layout'
import { space } from 'theme'

const DEFAULT_CONTAINER_WIDTH = '1200px'

const Container = styled(Box)`
  max-width: ${({ maxWidth }) => maxWidth || DEFAULT_CONTAINER_WIDTH};
  padding-left: ${space.m};
  padding-right: ${space.m};
  margin-left: auto;
  margin-right: auto;
`

Container.propTypes = {
  maxWidth: PropTypes.string,
}

export default Container
